import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

interface IconProps {
  type: IconProp;
  color?: string;
  size?: string;
}

const Icon: FC<IconProps> = ({ type, color, size }) => {
  return <FontAwesomeIcon icon={type} color={color} width={size} height={size} />;
};

export default Icon;
