import { faCalendarAlt, faChartLine, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import styled from 'styled-components';
import theme from 'theme';

import InfoCard from 'components/card/infoCard';
import TableCard from 'components/card/tableCard';
import Flex from 'components/layout/flex';
import Layout from 'components/layout/layout';
import Text from 'components/layout/text';
import Meta from 'components/seo/meta';

import { getBookmakerLogo } from 'utils/bookmaker';

const TableContainer = styled.div`
  margin-top: 3.2rem;
`;

const Image = styled.img`
  height: 2.4rem;
  width: auto;
`;

const Home: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t('aces.table.id'),
        accessor: 'id',
        width: 50,
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'IDCell' },
        ),
      },
      {
        Header: t('aces.table.player1'),
        accessor: 'player1',
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'Player1Cell' },
        ),
      },
      {
        Header: t('aces.table.player2'),
        accessor: 'player2',
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'Player2Cell' },
        ),
      },
      {
        Header: t('aces.table.cut'),
        accessor: 'cut',
        width: 80,
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'CutCell' },
        ),
      },
      {
        Header: t('aces.table.type'),
        accessor: 'type',
        width: 80,
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'TypeCell' },
        ),
      },
      {
        Header: t('aces.table.odd'),
        accessor: 'odd',
        width: 80,
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'OddCell' },
        ),
      },
      {
        Header: t('aces.table.date'),
        accessor: 'date',
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'DateCell' },
        ),
      },
      {
        Header: t('aces.table.bookmaker'),
        accessor: 'bookmaker',
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Image src={getBookmakerLogo(cell.value)} alt={`Logo de ${cell.value}`} />
          ),
          { displayName: 'BookmakerCell' },
        ),
      },
    ],
    [t],
  );

  const data = useMemo(
    () => [
      {
        id: '1',
        player1: 'Novak Djokovic',
        player2: 'Rafael Nadal',
        cut: '2',
        type: 'Over',
        odd: '1.50',
        date: '2021-06-20',
        bookmaker: 'winamax',
      },
      {
        id: '2',
        player1: 'Novak Djokovic',
        player2: 'Rafael Nadal',
        cut: '2',
        type: 'Over',
        odd: '1.50',
        date: '2021-06-20',
        bookmaker: 'unibet',
      },
      {
        id: '3',
        player1: 'Novak Djokovic',
        player2: 'Rafael Nadal',
        cut: '2',
        type: 'Over',
        odd: '1.50',
        date: '2021-06-20',
        bookmaker: 'winamax',
      },
      {
        id: '4',
        player1: 'Novak Djokovic',
        player2: 'Rafael Nadal',
        cut: '2',
        type: 'Over',
        odd: '1.50',
        date: '2021-06-20',
        bookmaker: 'betclic',
      },
    ],
    [],
  );

  return (
    <Layout>
      <Meta
        title={t('seo:title')}
        description={t('seo:description')}
        url="/"
        image="/favicon/android-chrome-512x512.png"
      />

      <Flex justify="space-between">
        <InfoCard
          title={t('home.bets_to_come')}
          info="13"
          icon={faCalendarAlt}
          footerText={t('home.from_today')}
        />

        <InfoCard
          title={t('home.total_won')}
          info={t('home.price', { price: 500 })}
          moreInfo={t('home.more_price', { price: 90 })}
          icon={faEuroSign}
          footerText={t('home.since_beginning')}
        />

        <InfoCard
          title={t('home.total_bets')}
          info="320"
          icon={faChartLine}
          footerText={t('home.since_beginning')}
          noMarginRight
        />
      </Flex>

      <TableContainer>
        <TableCard title={t('aces.title')} columns={columns} data={data} />
      </TableContainer>
    </Layout>
  );
};

export default Home;
