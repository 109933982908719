import { faChartLine, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import styled from 'styled-components';
import theme from 'theme';

import InfoCard from 'components/card/infoCard';
import TableCard from 'components/card/tableCard';
import Flex from 'components/layout/flex';
import Layout from 'components/layout/layout';
import Text from 'components/layout/text';
import Meta from 'components/seo/meta';

const TableContainer = styled.div`
  margin-top: 3.2rem;
`;

const Users: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t('users.table.id'),
        accessor: 'id',
        width: 50,
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'IDCell' },
        ),
      },
      {
        Header: t('users.table.firstname'),
        accessor: 'firstname',
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'FirstnameCell' },
        ),
      },
      {
        Header: t('users.table.lastname'),
        accessor: 'lastname',
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'LastnameCell' },
        ),
      },
      {
        Header: t('users.table.email'),
        accessor: 'email',
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text
              content={cell.value}
              color={theme.colors.primary[900]}
              size="1.4rem"
              style={{ wordBreak: 'break-all' }}
            />
          ),
          { displayName: 'EmailCell' },
        ),
      },
      {
        Header: t('users.table.phone'),
        accessor: 'phone',
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'PhoneCell' },
        ),
      },
      {
        Header: t('users.table.birthday'),
        accessor: 'birthday',
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'BirthdayCell' },
        ),
      },
      {
        Header: t('users.table.is_active'),
        accessor: 'isActive',
        width: 50,
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'IsActiveCell' },
        ),
      },
      {
        Header: t('users.table.is_admin'),
        accessor: 'isAdmin',
        width: 50,
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'IsAdminCell' },
        ),
      },
      {
        Header: t('users.table.created_at'),
        accessor: 'createdAt',
        Cell: Object.assign(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ cell }: CellProps<any>) => (
            <Text content={cell.value} color={theme.colors.primary[900]} size="1.4rem" />
          ),
          { displayName: 'CreatedAtCell' },
        ),
      },
    ],
    [t],
  );

  const data = useMemo(
    () => [
      {
        id: '1',
        firstname: 'John',
        lastname: 'Doe',
        email: 'johndoe@example.com',
        phone: '+33698670546',
        birthday: '01/01/2000',
        isActive: 'true',
        isAdmin: 'false',
        createdAt: '01/01/2021',
      },
      {
        id: '2',
        firstname: 'John',
        lastname: 'Doe',
        email: 'johndoe@example.com',
        phone: '+33698670546',
        birthday: '01/01/2000',
        isActive: 'false',
        isAdmin: 'false',
        createdAt: '01/01/2021',
      },
      {
        id: '3',
        firstname: 'John',
        lastname: 'Doe',
        email: 'johndoe@example.com',
        phone: '+33698670546',
        birthday: '01/01/2000',
        isActive: 'false',
        isAdmin: 'false',
        createdAt: '01/01/2021',
      },
      {
        id: '4',
        firstname: 'John',
        lastname: 'Doe',
        email: 'johndoe@example.com',
        phone: '+33698670546',
        birthday: '01/01/2000',
        isActive: 'true',
        isAdmin: 'true',
        createdAt: '01/01/2021',
      },
    ],
    [],
  );

  return (
    <Layout>
      <Meta
        title={t('seo:title')}
        description={t('seo:description')}
        url="/"
        image="/favicon/android-chrome-512x512.png"
      />

      <Flex justify="space-between">
        <InfoCard
          title={t('users.total_users')}
          info="13"
          icon={faChartLine}
          footerText={t('users.from_today')}
        />

        <InfoCard
          title={t('users.subscriptions_benefit')}
          info={t('users.price', { price: 500 })}
          moreInfo={t('users.more_price', { price: 90 })}
          icon={faEuroSign}
          footerText={t('users.since_beginning')}
        />

        <InfoCard
          title={t('users.total_subscriptions_benefit')}
          info={t('users.price', { price: 800 })}
          moreInfo={t('users.more_price', { price: 300 })}
          icon={faEuroSign}
          footerText={t('users.since_beginning')}
          noMarginRight
        />
      </Flex>

      <TableContainer>
        <TableCard title={t('users.title')} columns={columns} data={data} />
      </TableContainer>
    </Layout>
  );
};

export default Users;
