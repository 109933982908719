import { ReactElement, useMemo } from 'react';
import { Column, TableOptions, useTable } from 'react-table';
import styled from 'styled-components';
import theme from 'theme';

import Filters from 'components/table/filters';

// import Pagination from 'components/table/pagination';

interface TableProps<T extends Record<string, unknown>> extends TableOptions<T> {
  columns: Array<Column<T>>;
  data: Array<T>;
}

const Container = styled.div``;

const TableContainer = styled.div`
  overflow-x: scroll;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
`;

const THead = styled.thead``;

const TrHead = styled.tr``;

const Th = styled.th`
  color: ${theme.colors.secondary[700]};
  font-family: ${theme.fonts.primary};
  font-size: 1.4rem;
  font-weight: 500;
  padding: 1.6rem 0;
  text-align: left;
  text-transform: uppercase;
  vertical-align: bottom;

  &:last-child {
    text-align: center;
  }
`;

const TBody = styled.tbody``;

const TrBody = styled.tr``;

const Td = styled.td`
  display: table-cell;
  padding: 0.8rem 0;

  &:last-child {
    text-align: center;
  }
`;

const Table = <T extends Record<string, unknown>>({
  columns,
  data,
}: TableProps<T>): ReactElement => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 100,
      maxWidth: 300,
    }),
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    defaultColumn,
  });

  return (
    <Container>
      <Filters />

      <TableContainer>
        <StyledTable {...getTableProps()}>
          <THead>
            {headerGroups.map((headerGroup) => (
              <TrHead
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                    style={{
                      width: column.width,
                      maxWidth: column.maxWidth,
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.render('Header')}
                  </Th>
                ))}
              </TrHead>
            ))}
          </THead>

          <TBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TrBody {...row.getRowProps()} key={row.getRowProps().key}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()} key={cell.getCellProps().key}>
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </TrBody>
              );
            })}
          </TBody>
        </StyledTable>
      </TableContainer>

      {/* <Pagination /> */}
    </Container>
  );
};

export default Table;
