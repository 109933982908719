import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Error404 from 'pages/404';
import Account from 'pages/account';
import Aces from 'pages/aces';
import ForgotPassword from 'pages/forgotPassword';
import Home from 'pages/home';
import Login from 'pages/login';
import Signup from 'pages/signup';
import Statistics from 'pages/statistics';
import Users from 'pages/users';

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/account" element={<Account />} />
        <Route path="/aces" element={<Aces />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/users" element={<Users />} />

        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
