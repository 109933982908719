export const getMessage = (): string => {
  const actualHour = new Date().getHours();

  const messages = [
    {
      textId: 'morning',
      startHour: 6,
    },
    {
      textId: 'afternoon',
      startHour: 12,
    },
    {
      textId: 'evening',
      startHour: 17,
    },
    {
      textId: 'night',
      startHour: 21,
    },
  ];

  let actualMessage = messages[0];

  if (actualHour < messages[0].startHour) {
    actualMessage = messages[messages.length - 1];
  }
  messages.forEach((message) => {
    if (actualHour >= message.startHour) {
      actualMessage = message;
    }
  });

  return actualMessage.textId;
};
