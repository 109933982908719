import { faFilter, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';

import Button from 'components/layout/button';
import Flex from 'components/layout/flex';
import Input from 'components/layout/input';

const Container = styled.div``;

const Line = styled.span`
  background-color: ${theme.colors.grey[200]};
  display: block;
  height: 0.1rem;
  margin-top: 1.6rem;
  margin-left: -3.2rem;
  width: calc(100% + 6.4rem); // padding-left + padding-right
`;

const Filters: FC = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');

  const handleClick = () => {
    console.log('click');
  };

  return (
    <Container>
      <Flex align="center">
        <Input
          type="text"
          placeholder={t('table.filter.search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          iconLeft={faMagnifyingGlass}
          iconColor={theme.colors.secondary[100]}
          placeholderColor={theme.colors.secondary[100]}
          borderColor={theme.colors.secondary[100]}
          width="30rem"
          rounded
        />

        <Button
          content={t('table.filter.filters')}
          onClick={handleClick}
          marginLeft="4.8rem"
          outline
          iconLeft={faFilter}
          iconColor={theme.colors.secondary[300]}
          textColor={theme.colors.secondary[300]}
          borderColor={theme.colors.secondary[100]}
        />
      </Flex>

      <Line />
    </Container>
  );
};

export default Filters;
