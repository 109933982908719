import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import Icon from 'components/layout/icon';

interface ButtonProps {
  content: string;
  type?: 'button' | 'submit' | 'reset';
  marginBottom?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  width?: string;
  disabled?: boolean;
  rounded?: boolean;
  onClick?: () => void;
  outline?: boolean;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  iconColor?: string;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
}

interface ContainerProps {
  marginBottom?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  width?: string;
  disabled?: boolean;
  rounded?: boolean;
  outline?: boolean;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
}

interface IconContainerProps {
  iconLeft?: boolean;
  iconRight?: boolean;
}

const Container = styled.button<ContainerProps>`
  background-color: ${({ outline, backgroundColor }) =>
    backgroundColor || (outline ? 'transparent' : theme.colors.primary[500])};
  border: ${({ outline, borderColor }) =>
    outline && `0.1rem solid ${borderColor || theme.colors.primary[500]}`};
  border-radius: ${({ rounded }) => (rounded ? '5rem' : '0.4rem')};
  color: ${({ outline, textColor }) =>
    textColor || (outline ? theme.colors.primary[500] : theme.colors.grey[100])};
  cursor: pointer;
  font-family: ${theme.fonts.primary};
  font-size: 1.6rem;
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  opacity: ${({ disabled }) => disabled && 0.4};
  padding: 0.8rem 2.4rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  width: ${({ width }) => width && width};

  svg {
    transition: color 0.2s ease-in-out;
  }

  &:focus {
    background-color: ${({ outline }) => !outline && theme.colors.primary[600]};
    border-color: ${({ outline }) => outline && theme.colors.primary[600]};
    color: ${({ outline }) => outline && theme.colors.primary[600]};

    svg {
      color: ${({ outline }) => outline && theme.colors.primary[600]};
    }
  }

  &:hover {
    background-color: ${({ outline }) => !outline && theme.colors.primary[700]};
    border-color: ${({ outline }) => outline && theme.colors.primary[700]};
    color: ${({ outline }) => outline && theme.colors.primary[700]};
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.2);

    svg {
      color: ${({ outline }) => outline && theme.colors.primary[700]};
    }
  }

  &:active {
    background-color: ${({ outline }) => !outline && theme.colors.primary[800]};
    border-color: ${({ outline }) => outline && theme.colors.primary[800]};
    color: ${({ outline }) => outline && theme.colors.primary[800]};

    svg {
      color: ${({ outline }) => outline && theme.colors.primary[800]};
    }
  }
`;

const IconContainer = styled.span<IconContainerProps>`
  margin-left: ${({ iconRight }) => iconRight && '0.8rem'};
  margin-right: ${({ iconLeft }) => iconLeft && '0.8rem'};
`;

const Button: FC<ButtonProps> = ({
  content,
  type,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  width,
  disabled,
  rounded,
  onClick,
  outline,
  iconLeft,
  iconRight,
  iconColor = theme.colors.grey[900],
  textColor,
  borderColor,
  backgroundColor,
}) => {
  return (
    <Container
      type={type}
      marginBottom={marginBottom}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      width={width}
      disabled={disabled}
      rounded={rounded}
      onClick={onClick}
      outline={outline}
      textColor={textColor}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      {iconLeft && (
        <IconContainer iconLeft>
          <Icon type={iconLeft} color={iconColor} size="1.2rem" />
        </IconContainer>
      )}

      {content}

      {iconRight && (
        <IconContainer iconRight>
          <Icon type={iconRight} color={iconColor} size="1.2rem" />
        </IconContainer>
      )}
    </Container>
  );
};

export default Button;
