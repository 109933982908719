import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import Card from 'components/card/card';
import Flex from 'components/layout/flex';
import Icon from 'components/layout/icon';
import Text from 'components/layout/text';

interface InfoCardProps {
  title: string;
  info: string;
  moreInfo?: string;
  icon: IconProp;
  footerText: string;
  noMarginRight?: boolean;
}

const IconContainer = styled.span`
  background-color: ${theme.colors.primary[100]};
  border-radius: 50%;
  height: 3.2rem;
  position: relative;
  width: 3.2rem;

  > * {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const InfoCard: FC<InfoCardProps> = ({
  title,
  info,
  moreInfo,
  icon,
  footerText,
  noMarginRight,
}) => {
  return (
    <Card
      maxWidth="30rem"
      marginRight={noMarginRight ? '0' : '1.6rem'}
      padding="0.8rem 1.6rem 0.4rem 1.6rem"
    >
      <Flex direction="column" justify="space-between">
        <Text
          content={title}
          color={theme.colors.secondary[500]}
          size="1.4rem"
          weight="500"
          marginBottom="1.6rem"
        />
        <Flex align="center" justify="space-between" marginBottom="1.6rem">
          <Flex align="center">
            <Text content={info} color={theme.colors.secondary[500]} size="3.2rem" weight="500" />

            {moreInfo && (
              <Text
                content={moreInfo}
                color={theme.colors.success[500]}
                size="1.4rem"
                marginLeft="0.8rem"
              />
            )}
          </Flex>

          <IconContainer>
            <Icon type={icon} size="1.6rem" color={theme.colors.primary[500]} />
          </IconContainer>
        </Flex>

        <Text content={footerText} color={theme.colors.secondary[200]} size="1.2rem" />
      </Flex>
    </Card>
  );
};

export default InfoCard;
