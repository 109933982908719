import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FC, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import Icon from 'components/layout/icon';

interface InputProps {
  placeholder: string;
  type: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderColor?: string;
  width?: string;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  iconColor?: string;
  minWidth?: string;
  maxWidth?: string;
  label?: string;
  labelColor?: string;
  rounded?: boolean;
  disabled?: boolean;
}

interface ContainerProps {
  disabled?: boolean;
}

interface InputContainerProps {
  width?: string;
}

interface InputCustomProps {
  placeholderColor?: string;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  iconColor?: string;
  minWidth?: string;
  maxWidth?: string;
  rounded?: boolean;
}

interface IconContainerProps {
  iconLeft?: boolean;
  iconRight?: boolean;
  onClick?: (event: MouseEvent) => void;
}

interface LabelProps {
  labelColor?: string;
}

const Container = styled.div<ContainerProps>`
  opacity: ${({ disabled }) => disabled && 0.4};
`;

const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  width: ${({ width }) => width || '100%'};
`;

const InputCustom = styled.input<InputCustomProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border: 0.1rem solid ${({ borderColor }) => borderColor || theme.colors.secondary[200]};
  border-radius: ${({ rounded }) => (rounded ? '5rem' : '0.4rem')};
  color: ${({ textColor }) => textColor || theme.colors.secondary[500]};
  font-family: ${theme.fonts.primary};
  font-size: 1.4rem;
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  min-width: ${({ minWidth }) => minWidth && minWidth};
  padding-bottom: 0.8rem;
  padding-left: ${({ iconLeft }) => (iconLeft ? '2.8rem' : '0.8rem')};
  padding-right: ${({ iconRight, type }) =>
    iconRight || type === 'password' ? '2.8rem' : '0.8rem'};
  padding-top: 0.8rem;
  transition: color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  width: 100%;

  &::placeholder {
    color: ${({ placeholderColor }) => placeholderColor || theme.colors.secondary[200]};
  }

  &:focus {
    border: 0.1rem solid ${theme.colors.primary[500]};
    color: ${theme.colors.primary[500]};
  }
`;

const IconContainer = styled.span<IconContainerProps>`
  cursor: ${({ onClick }) => onClick && 'pointer'};
  left: ${({ iconLeft }) => (iconLeft ? '0.8rem' : 'unset')};
  position: absolute;
  right: ${({ iconRight }) => (iconRight ? '0.8rem' : 'unset')};
  top: 50%;
  transform: translateY(-50%);
`;

const Label = styled.label<LabelProps>`
  color: ${({ labelColor }) => labelColor || theme.colors.primary[700]};
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
`;

const Input: FC<InputProps> = ({
  placeholder,
  type,
  value,
  onChange,
  placeholderColor,
  width,
  textColor,
  borderColor,
  backgroundColor,
  iconLeft,
  iconRight,
  iconColor = theme.colors.secondary[200],
  minWidth,
  maxWidth,
  label,
  labelColor,
  rounded,
  disabled,
}) => {
  const [isShow, setIsShow] = useState<boolean>(false);

  return (
    <Container>
      {label && <Label labelColor={labelColor}>{label}</Label>}

      <InputContainer width={width}>
        {iconLeft && (
          <IconContainer iconLeft>
            <Icon type={iconLeft} color={iconColor} size="1.2rem" />
          </IconContainer>
        )}

        <InputCustom
          placeholder={placeholder}
          type={type === 'password' ? (isShow ? 'text' : 'password') : type}
          value={value}
          onChange={onChange}
          placeholderColor={placeholderColor}
          textColor={textColor}
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          iconLeft={iconLeft}
          iconRight={iconRight}
          minWidth={minWidth}
          maxWidth={maxWidth}
          rounded={rounded}
          disabled={disabled}
        />

        {iconRight && (
          <IconContainer iconRight>
            <Icon type={iconRight} color={iconColor} size="1.2rem" />
          </IconContainer>
        )}

        {type === 'password' && (
          <IconContainer iconRight onClick={() => setIsShow(!isShow)}>
            <Icon type={isShow ? faEye : faEyeSlash} color={iconColor} size="1.2rem" />
          </IconContainer>
        )}
      </InputContainer>
    </Container>
  );
};

export default Input;
