import {
  faChartPie,
  faCog,
  faCrosshairs,
  faHouse,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

import Flex from 'components/layout/flex';
import Icon from 'components/layout/icon';
import Text from 'components/layout/text';

interface LineProps {
  marginBottom?: string;
}

interface LinkContainerProps {
  active?: boolean;
}

const Container = styled.nav`
  background-color: ${theme.colors.grey[100]};
  width: 18rem;
  position: fixed;
  top: 9.6rem; // header + margin
  left: 0;
  height: calc(100% - 9.6rem);
  padding: 1.6rem 0;
`;

const MenuSection = styled.div`
  margin-bottom: 4.8rem;
`;

const Line = styled.span<LineProps>`
  background-color: ${theme.colors.grey[200]};
  display: block;
  height: 0.1rem;
  margin-bottom: ${({ marginBottom }) => marginBottom || '1.6rem'};
  width: 100%;
`;

const LinkContainer = styled.div<LinkContainerProps>`
  background-color: ${({ active }) => active && theme.colors.primary[100]};
  padding: 0.8rem 3.2rem;
  position: relative;
  transition: background-color 0.2s ease-in-out;

  p,
  svg {
    color: ${({ active }) => active && theme.colors.primary[500]};
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${theme.colors.primary[100]};

    p,
    svg {
      color: ${theme.colors.primary[500]};
    }
  }

  &:before {
    background-color: ${theme.colors.primary[500]};
    content: '';
    height: 100%;
    left: 0;
    opacity: ${({ active }) => (active ? 1 : 0)};
    position: absolute;
    top: 0;
    transition: opacity 0.2s ease-in-out;
    width: 0.2rem;
  }
`;

const BottomSection = styled.div`
  bottom: 0.8rem;
  left: 0;
  position: absolute;
  width: 100%;
`;

const SideMenu: FC = () => {
  const { t } = useTranslation();

  const menu = [
    {
      links: [
        {
          name: t('side_menu.general.home'),
          path: '/',
          icon: faHouse,
        },
        {
          name: t('side_menu.general.aces'),
          path: '/aces',
          icon: faCrosshairs,
        },
        {
          name: t('side_menu.general.statistics'),
          path: '/statistics',
          icon: faChartPie,
        },
      ],
    },
    {
      title: t('side_menu.administration.title'),
      links: [
        {
          name: t('side_menu.administration.users'),
          path: '/users',
          icon: faUserFriends,
        },
      ],
    },
  ];

  return (
    <Container>
      {menu.map((item, itemIndex) => {
        return (
          <MenuSection key={`section_${itemIndex}`}>
            {itemIndex !== 0 && <Line />}

            {item.title && (
              <Text
                content={item.title}
                color={theme.colors.secondary[300]}
                size="1.2rem"
                weight="500"
                marginBottom="0.8rem"
                uppercase
                style={{ paddingLeft: '3.2rem', paddingRight: '3.2rem' }}
              />
            )}

            {item.links.map((link, linkIndex) => {
              return (
                <NavLink to={link.path} key={`section_${itemIndex}_link_${linkIndex}`}>
                  {({ isActive }) => (
                    <LinkContainer active={isActive}>
                      <Flex align="center">
                        <Icon type={link.icon} size="1.6rem" color={theme.colors.secondary[200]} />
                        <Text
                          content={link.name}
                          color={theme.colors.secondary[200]}
                          size="1.4rem"
                          weight="500"
                          marginLeft="0.8rem"
                        />
                      </Flex>
                    </LinkContainer>
                  )}
                </NavLink>
              );
            })}
          </MenuSection>
        );
      })}

      <BottomSection>
        <Line marginBottom="0.8rem" />

        <NavLink to="/account">
          {({ isActive }) => (
            <LinkContainer active={isActive}>
              <Flex align="center">
                <Icon type={faCog} size="1.6rem" color={theme.colors.secondary[200]} />
                <Text
                  content={t('side_menu.settings')}
                  color={theme.colors.secondary[200]}
                  size="1.4rem"
                  weight="500"
                  marginLeft="0.8rem"
                />
              </Flex>
            </LinkContainer>
          )}
        </NavLink>
      </BottomSection>
    </Container>
  );
};

export default SideMenu;
