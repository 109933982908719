import { ReactElement } from 'react';
import { Column, TableOptions } from 'react-table';
import styled from 'styled-components';
import theme from 'theme';

import Card from 'components/card/card';
import Text from 'components/layout/text';
import Table from 'components/table/table';

interface InfoCardProps<T extends Record<string, unknown>> extends TableOptions<T> {
  title: string;
  columns: Array<Column<T>>;
  data: Array<T>;
}

const Line = styled.span`
  background-color: ${theme.colors.grey[200]};
  display: block;
  height: 0.1rem;
  margin-bottom: 1.6rem;
  margin-left: -3.2rem;
  width: calc(100% + 6.4rem); // padding-left + padding-right
`;

const InfoCard = <T extends Record<string, unknown>>({
  title,
  columns,
  data,
}: InfoCardProps<T>): ReactElement => {
  return (
    <Card padding="1.6rem 3.2rem">
      <Text
        content={title}
        color={theme.colors.secondary[500]}
        size="2.4rem"
        weight="500"
        marginBottom="1.6rem"
      />

      <Line />

      <Table columns={columns} data={data} />
    </Card>
  );
};

export default InfoCard;
