export const getBookmakerLogo = (bookmaker: BookmakersEnum): string => {
  const path = `assets/images/bookmakers/`;

  const bookmakersLogo: Bookmakers = {
    winamax: 'winamax.png',
    betclic: 'betclic.png',
    unibet: 'unibet.png',
  };

  return path + bookmakersLogo[bookmaker];
};
