import { FC } from 'react';
import styled from 'styled-components';
import theme from 'theme';

interface CardProps {
  className?: string;
  padding?: string;
  width?: string;
  maxWidth?: string;
  marginBottom?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
}

interface ContainerProps {
  padding?: string;
  width?: string;
  maxWidth?: string;
  marginBottom?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<ContainerProps>`
  background-color: ${theme.colors.grey[100]};
  border-radius: 0.4rem;
  box-shadow: ${theme.shadows.medium};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  padding: ${({ padding }) => padding || '2.4rem'};
  width: ${({ width }) => width || '100%'};
`;

const Card: FC<CardProps> = ({
  children,
  className,
  padding,
  width,
  maxWidth,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
}) => {
  return (
    <Container
      className={className}
      padding={padding}
      width={width}
      maxWidth={maxWidth}
      marginBottom={marginBottom}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      {children}
    </Container>
  );
};

export default Card;
