import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';

import Account from 'components/header/account';
import Logo from 'components/header/logo';
import Input from 'components/layout/input';
import Requires from 'components/layout/requires';

const Container = styled.header`
  align-items: center;
  background-color: ${theme.colors.grey[100]};
  display: flex;
  height: 8rem;
  justify-content: space-between;
  left: 0;
  padding: 1.6rem 3.2rem;
  position: fixed;
  top: 0;
  width: 100%;
`;

const Header: FC = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');

  return (
    <Container>
      <Logo />

      <Requires value={true}>
        <Input
          type="text"
          placeholder={t('header.search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          iconLeft={faMagnifyingGlass}
          iconColor={theme.colors.primary[500]}
          placeholderColor={theme.colors.primary[500]}
          borderColor={theme.colors.primary[100]}
          backgroundColor="rgba(236, 241, 254, 0.4)"
          width="40rem"
          rounded
        />

        <Account />
      </Requires>
    </Container>
  );
};

export default Header;
