import { FC } from 'react';
import styled from 'styled-components';

// import Footer from 'components/layout/footer';
import Header from 'components/layout/header';
import SideMenu from 'components/layout/sideMenu';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 2.4rem 3.2rem;
  width: 100vw;
`;

const Main = styled.main`
  margin-top: 7.2rem; // header - padding + margin
  margin-left: 18.8rem; // sideMenu - padding + margin
`;

const Layout: FC = ({ children }) => {
  return (
    <Container>
      <Header />
      <SideMenu />
      <Main>{children}</Main>
      {/* <Footer /> */}
    </Container>
  );
};

export default Layout;
