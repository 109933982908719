import { FC } from 'react';
import styled from 'styled-components';
import theme from 'theme';

interface TextProps {
  content?: string;
  dangerouslySetInnerHTML?: { __html: string };
  type?: React.ElementType;
  color?: string;
  size?: string;
  weight?: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
  uppercase?: boolean;
  lineHeight?: string;
  marginBottom?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  width?: string;
  align?: 'left' | 'center' | 'right';
  hoverColor?: string;
  style?: React.CSSProperties;
}

interface TextContainerProps {
  color?: string;
  size?: string;
  weight?: string;
  uppercase?: boolean;
  lineHeight?: string;
  marginBottom?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  width?: string;
  align?: string;
  hoverColor?: string;
}

const Container = styled.p<TextContainerProps>`
  color: ${({ color }) => (color ? color : theme.colors.grey[900])};
  font-family: ${theme.fonts.primary};
  font-size: ${({ size }) => (size ? size : '1.6rem')};
  font-weight: ${({ weight }) => weight && weight};
  line-height: ${({ lineHeight }) => lineHeight && lineHeight};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  text-align: ${({ align }) => align && align};
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
  transition: color 0.2s ease-in-out;
  width: ${({ width }) => width && width};

  &:hover {
    color: ${({ hoverColor }) => hoverColor && hoverColor};
  }
`;

const Text: FC<TextProps> = ({
  content,
  dangerouslySetInnerHTML,
  type,
  color,
  size,
  weight,
  uppercase,
  lineHeight,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  width,
  children,
  align,
  hoverColor,
  style,
}) => {
  return (
    <Container
      color={color}
      size={size}
      weight={weight}
      uppercase={uppercase}
      lineHeight={lineHeight}
      marginBottom={marginBottom}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      width={width}
      align={align}
      as={type || dangerouslySetInnerHTML ? 'div' : type}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      hoverColor={hoverColor}
      style={style}
    >
      {content && (
        <>
          {content}
          {children}
        </>
      )}
    </Container>
  );
};

export default Text;
