import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';

import Flex from 'components/layout/flex';
import Icon from 'components/layout/icon';
import Text from 'components/layout/text';

import { getMessage } from 'utils/message';

const IconContainer = styled.span`
  position: relative;

  &:after {
    background-color: ${theme.colors.danger[700]};
    border-radius: 50%;
    content: '';
    height: 0.6rem;
    position: absolute;
    right: -0.2rem;
    top: -0.2rem;
    width: 0.6rem;
  }
`;

const UserIcon = styled.span`
  align-items: center;
  background-color: ${theme.colors.primary[100]};
  border: 0.1rem solid ${theme.colors.primary[700]};
  border-radius: 50%;
  display: flex;
  height: 3.2rem;
  justify-content: center;
  margin-left: 1.6rem;
  position: relative;
  width: 3.2rem;

  &:after {
    background-color: ${theme.colors.success[700]};
    border: 0.1rem solid ${theme.colors.grey[100]};
    border-radius: 50%;
    bottom: -0.1rem;
    content: '';
    height: 0.8rem;
    position: absolute;
    right: -0.1rem;
    width: 0.8rem;
  }
`;

const Account: FC = () => {
  const { t } = useTranslation();

  const message = t(`header.account.${getMessage()}`, {
    name: 'John',
  });

  return (
    <Flex align="center">
      <IconContainer>
        <Icon type={faBell} color={theme.colors.primary[700]} size="1.6rem" />
      </IconContainer>

      <Text content={message} color={theme.colors.primary[700]} size="1.2rem" marginLeft="2.4rem" />

      <UserIcon>
        <Text content="J" color={theme.colors.primary[700]} size="2.4rem" weight="500" />
      </UserIcon>
    </Flex>
  );
};

export default Account;
