import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

import Card from 'components/card/card';
import Form from 'components/form/form';
import Flex from 'components/layout/flex';
import Layout from 'components/layout/layout';
import Text from 'components/layout/text';
import Meta from 'components/seo/meta';

const CardContainer = styled.div`
  width: 100%;

  & > * {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Image = styled.img`
  margin-bottom: -2.4rem;
  max-height: 24rem;
  object-fit: cover;
  padding-right: 1.6rem;
`;

const Login: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const fields: Field[] = [
    {
      name: 'email',
      value: '',
      type: 'email',
      placeholder: t('login.email.placeholder'),
      label: t('login.email.label'),
    },
    {
      name: 'password',
      value: '',
      type: 'password',
      placeholder: t('login.password.placeholder'),
      label: t('login.password.label'),
      forgotPassword: true,
    },
  ];

  const onSubmit = (data: Login) => {
    console.log(data);
  };

  return (
    <Layout>
      <Meta
        title={t('seo:title')}
        description={t('seo:description')}
        url="/"
        image="/favicon/android-chrome-512x512.png"
      />

      <CardContainer>
        <Card width="80%">
          <Flex>
            <Flex width="50%" self="end">
              <Image src="assets/images/dashboard.png" alt="Dashboard" />
            </Flex>

            <Flex direction="column" width="50%" style={{ paddingLeft: '1.6rem' }}>
              <Text
                content={t('login.title')}
                color={theme.colors.secondary[500]}
                size="2.4rem"
                weight="500"
                marginBottom="1.6rem"
              />

              <Form fields={fields} onSubmit={onSubmit} submitText={t('login.submit')} />

              <Flex marginTop="4rem">
                <Text
                  dangerouslySetInnerHTML={{ __html: t('login.no_account') }}
                  size="1.2rem"
                  color={theme.colors.secondary[700]}
                />

                <Link to="/signup">
                  <Text
                    content={t('login.signup')}
                    size="1.2rem"
                    weight="500"
                    color={theme.colors.primary[500]}
                    hoverColor={theme.colors.primary[700]}
                  />
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </CardContainer>
    </Layout>
  );
};

export default Login;
