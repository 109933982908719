import { FC } from 'react';

interface RequiresProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

const Requires: FC<RequiresProps> = ({ value, children }) => {
  if (!!value) return <>{children}</>;
  return null;
};

export default Requires;
