import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

import Button from 'components/layout/button';
import Input from 'components/layout/input';
import Text from 'components/layout/text';

interface FormProps {
  fields: Field[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: any) => void;
  submitText: string;
}

interface InputContainerProps {
  width?: string;
  index: number;
}

const Container = styled.form`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
`;

const InputContainer = styled.div<InputContainerProps>`
  margin-right: ${({ width, index }) => width === '50%' && (index % 2 === 0 ? '1.6rem' : 0)};
  margin-top: 1.6rem;
  width: ${({ width }) => (width ? `calc(${width} - 0.8rem)` : '100%')};
`;

const Form: FC<FormProps> = ({ fields, onSubmit, submitText }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, getValues } = useForm();

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, index) => {
        const { name, value, type, placeholder, label, forgotPassword, width } = field;

        return (
          <InputContainer key={`field_${name}`} width={width} index={index}>
            <Controller
              name={name}
              rules={{ required: true }}
              control={control}
              render={({ field: f }) => (
                <>
                  <Input
                    value={getValues(name) ?? value}
                    onChange={(e) => {
                      f.onChange(e);
                    }}
                    label={label}
                    type={type}
                    placeholder={placeholder}
                  />

                  {forgotPassword && (
                    <Link to="/forgot-password">
                      <Text
                        content={t('login.password.forgot')}
                        color={theme.colors.primary[500]}
                        size="1.2rem"
                        weight="500"
                        marginTop="0.8rem"
                        align="right"
                        hoverColor={theme.colors.primary[700]}
                      />
                    </Link>
                  )}
                </>
              )}
            />
          </InputContainer>
        );
      })}

      <Button type="submit" content={submitText} marginTop="3.2rem" />
    </Container>
  );
};

export default Form;
