import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Layout from 'components/layout/layout';
import Meta from 'components/seo/meta';

const ForgotPassword: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  return (
    <Layout>
      <Meta
        title={t('seo:title')}
        description={t('seo:description')}
        url="/"
        image="/favicon/android-chrome-512x512.png"
      />
    </Layout>
  );
};

export default ForgotPassword;
